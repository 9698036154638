import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";
import { Box, Button, Container, Input, Table, Pagination, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function Users() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [users, setUser] = useState(null);
    const [user0, setUser0] = useState(null);
    const [load, setLoad] = useState(false);
    const [searched, setSearched] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const [employee, setEmployee] = useState();
    useEffect(() => {
        if (load == false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getall`, {})
                .then((res) => {
                    setUser(res.data.data);
                    setUser0(res.data.data);
                    setLoad(true);
                    setLoading(false);
                    localStorage.setItem("data", JSON.stringify(res.data.data));
                })
                .then((res) => setLoad(true));
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getEmployee`, {})
                .then((res) => {
                    setEmployee(res.data.data);
                    setLoad(true);
                    localStorage.setItem("data1", JSON.stringify(res.data.data));
                })
                .then((res) => setLoad(true));
        }
    }, [load]);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = JSON.parse(localStorage.getItem("data")).filter((row) => {
                const a =
                    row?.username?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row?.name?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const b =
                    Number(row?.iduser).toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row?.stk_bank?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const c =
                    row?.ip?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row?.name_bank?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const d =
                    row?.sdt?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    searchedVal.toString().toLowerCase().includes(row?.sdt?.toString().toLowerCase());
                return a || b || c || d;
            });
            setPage(1);
            setUser(filteredRows);
        } else {
            setUser(JSON.parse(localStorage.getItem("data")));
        }
    };
    const [pages, setPage] = useState(1);

    const handleChange = (e, page) => {
        setPage(page);
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            {loading === true ? (
                                <div className="loading" style={{ maxWidth: "100%" }}>
                                    <div className="loader"></div>
                                </div>
                            ) : null}
                            {users !== null ? (
                                <Container maxWidth={false}>
                                    <div className="container_set">Danh sách user</div>
                                    <div className="form_set">
                                        <Box sx={{ minWidth: 600 }}>
                                            <TextField
                                                value={searched}
                                                onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                                placeholder="Tìm kiếm"
                                                sx={{ marginBottom: "5px", paddingRight: "700px" }}
                                            />
                                            <Table className="table-admin">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ID User</TableCell>
                                                        <TableCell>Username</TableCell>
                                                        <TableCell>Số điện thoại</TableCell>
                                                        <TableCell>Đại lý</TableCell>
                                                        <TableCell>Số Dư</TableCell>
                                                        <TableCell>Tổng Cược</TableCell>
                                                        <TableCell>Tổng Thắng</TableCell>
                                                        <TableCell>Rút/ Nạp Tiền</TableCell>
                                                        <TableCell>Ngân hàng</TableCell>
                                                        <TableCell>Thưởng</TableCell>
                                                        <TableCell>IP cuối</TableCell>
                                                        <TableCell>Xem Thông Tin</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {users != null ? (
                                                    <TableBody>
                                                        {users?.slice((pages - 1) * 30, (pages - 1) * 30 + 30).map((item) => (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.iduser}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px", color: "red" }}>{item?.username}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.sdt}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                        {user0.find((x) => x?.code === item?.aff)?.username || "admin"}
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.money?.toLocaleString("vi-VN")}</TableCell>

                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.totalbet.toLocaleString("vi-VN")}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.totalwin.toLocaleString("vi-VN")}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                        <form
                                                                            onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                setLoading(true);
                                                                                const dataForm = {
                                                                                    id: item?._id,
                                                                                    money: e.target.money.value
                                                                                };
                                                                                axios.post(`${process.env.REACT_APP_API_URL}/auth/update`, dataForm).then((res) => {
                                                                                    setUser(res.data.data);
                                                                                    setLoad(false);
                                                                                    swal("Cộng/Trừ tiền thành công!");
                                                                                    setLoading(false);
                                                                                });
                                                                            }}>
                                                                            <input style={{ width: "100px" }} id={item?._id} name="money" type="number" placeholder="Nhập tiền" />
                                                                            <Button type="submit">Xác nhận</Button>
                                                                        </form>
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                        {item?.stk_bank && (
                                                                            <>
                                                                                <span>{item?.stk_bank}</span>
                                                                                <br />
                                                                                <span>{item?.name_bank}</span>
                                                                                <br />
                                                                                <span>{item?.bank}</span>
                                                                            </>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                        <form
                                                                            onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                setLoading(true);
                                                                                const dataForm = {
                                                                                    id: item?._id,
                                                                                    money: Number(e.target.money.value),
                                                                                    detail: e.target.detail.value
                                                                                };
                                                                                axios.post(`${process.env.REACT_APP_API_URL}/auth/adminthuong`, dataForm).then((res) => {
                                                                                    setLoad(false);
                                                                                    swal("Thưởng thành công!");
                                                                                    e.target.reset();
                                                                                    setLoading(false);
                                                                                });
                                                                            }}>
                                                                            <input style={{ width: "100px" }} name="detail" type="text" placeholder="Nội dung" />
                                                                            <input style={{ width: "100px" }} id={item?._id} name="money" type="number" placeholder="Nhập tiền" />
                                                                            <Button type="submit">Xác nhận</Button>
                                                                        </form>
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.ip}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", display: "flex" }}>
                                                                        {item?.isLock == false ? (
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setLoading(true);
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_API_URL}/auth/lockkey`, {
                                                                                            id: item?._id,
                                                                                            isLock: true
                                                                                        })
                                                                                        .then((res) => {
                                                                                            setLoad(false);
                                                                                            swal("Thành công", "Khóa thành công", "success");
                                                                                        });
                                                                                }}>
                                                                                Khóa
                                                                            </Button>
                                                                        ) : (
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setLoading(true);
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_API_URL}/auth/lockkey`, {
                                                                                            id: item?._id,
                                                                                            isLock: false
                                                                                        })
                                                                                        .then((res) => {
                                                                                            setLoad(false);
                                                                                            swal("Thành công", "Mở khóa thành công", "success");
                                                                                        });
                                                                                }}>
                                                                                Mở
                                                                            </Button>
                                                                        )}
                                                                        <Button onClick={() => navigate(`/admin/user/${item?._id}`)}>Xem</Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                    </TableBody>
                                                ) : (
                                                    <div>Đang cập nhật</div>
                                                )}
                                            </Table>
                                        </Box>
                                    </div>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            pt: 3
                                        }}>
                                        {users != null ? <Pagination color="primary" count={Math.floor(users.length / 30) + 1} size="small" onChange={handleChange} /> : null}
                                    </Box>
                                </Container>
                            ) : (
                                <div className="loading" style={{ maxWidth: "100%" }}>
                                    <div className="loader"></div>
                                </div>
                            )}
                        </Box>
                    }
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default Users;
